import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CompanySubscriptionPortalService {
  private readonly http = inject(HttpService);

  public getCompanySubscriptionPortal(searchParam?: any): Observable<{ url: string }> {
    return this.http.getV2(`/api/companies/subscriptionPortal/session`, null, searchParam);
  }
}
