<input
  autocomplete="new-password"
  [disabled]="isDisabled"
  [type]="isVisible ? 'text' : 'password'"
  [(ngModel)]="value"
  id="confirmPassword"
  placeholder="{{ placeholder | translate }}"
/>

@if (value) {
  <div
    class="password-input_visibility-btn"
    [appFocusable]
    (click)="toggleVisibility()"
  >
    {{ (isVisible ? 'common.controls.password.hide' : 'common.controls.password.show') | translate }}
  </div>
}

@if (value && !isValid) {
  <div class="password-input_errors">
    <div>{{ 'common.controls.password.passwordMustInclude' | translate }}</div>
    <div
      class="password-input_error"
      [class.valid]="validations.minLength"
    >
      <img
        [src]="validations.minLength ? 'assets/svg/common/success-icon.svg' : 'assets/svg/common/error-cross-icon.svg'"
      />
      <span innerHtml="{{ 'common.controls.password.validations.minLength' | translate }}"></span>
    </div>
    <div
      class="password-input_error"
      [class.valid]="validations.capitalLetter"
    >
      <img
        [src]="
          validations.capitalLetter ? 'assets/svg/common/success-icon.svg' : 'assets/svg/common/error-cross-icon.svg'
        "
      />
      <span innerHtml="{{ 'common.controls.password.validations.capitalLetter' | translate }}"></span>
    </div>
    <div
      class="password-input_error"
      [class.valid]="validations.oneNumber"
    >
      <img
        [src]="validations.oneNumber ? 'assets/svg/common/success-icon.svg' : 'assets/svg/common/error-cross-icon.svg'"
      />
      <span innerHtml="{{ 'common.controls.password.validations.oneNumber' | translate }}"></span>
    </div>
    <div
      class="password-input_error"
      [class.valid]="validations.specialCharacter"
    >
      <img
        [src]="
          validations.specialCharacter ? 'assets/svg/common/success-icon.svg' : 'assets/svg/common/error-cross-icon.svg'
        "
      />
      <span innerHtml="{{ 'common.controls.password.validations.specialCharacter' | translate }}"></span>
    </div>
  </div>
}
