import { Component, forwardRef, Input } from '@angular/core';
import {
  type AbstractControl,
  type ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  type ValidationErrors,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FocusableDirective, TranslateModule, FormsModule],
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input() placeholder = 'common.controls.password.placeholder';
  public isDisabled = false;
  public isVisible = false;
  public validations = {
    minLength: false,
    capitalLetter: false,
    oneNumber: false,
    specialCharacter: false,
  };

  private _value = '';

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    this._value = val;
    this.validations.minLength = val.length >= 8;
    this.validations.oneNumber = val.search(/\d/) !== -1;
    this.validations.capitalLetter = val.search(/[A-Z]/) !== -1;
    this.validations.specialCharacter = val.search(/[#?!@$%^&*-]/) !== -1;
    this.onValidationChange();
    this.onChange(val);
    this.onTouched();
  }

  public get isValid(): boolean {
    return (
      this.validations.minLength &&
      this.validations.capitalLetter &&
      this.validations.oneNumber &&
      this.validations.specialCharacter
    );
  }

  public toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  public validate(control: AbstractControl): ValidationErrors {
    return this.isValid ? null : { passwordInvalid: true };
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: any) => {};

  private onTouched = () => {};

  private onValidationChange: any = () => {};
}
