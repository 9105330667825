export const DEFAULT_COLOR = '#1EA2E2';
export const DEFAULT_HEADER_TEXT = 'It’s nice to meet you!';
export const DEFAULT_BODY_TEXT = {
  ops: [
    {
      insert:
        '[[Sender-User-Name]] has invited you to connect. [[Sender-Company]] uses a platform called Clover to allow its Buyers to manage their relationships with Sellers like you. Connecting is the first step in this process. It only takes a couple of minutes, and you’ll be up and running. Don’t worry though, we’ll walk you through it, step by step. \n\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'It’s important to respond to this Buyer connection request quickly',
    },
    {
      insert:
        ', as these are typically sent as a part of a sourcing event where your company is being considered. To get going, just click on the Sign Up button. \n',
    },
  ],
  html: '<p>[[Sender-User-Name]] has invited you to connect.&nbsp;[[Sender-Company]] uses a platform called Clover to allow its Buyers to manage their relationships with Sellers like you.&nbsp;Connecting is the first step in this process.&nbsp;It only takes a couple of minutes, and you’ll be up and running.&nbsp;Don’t worry though, we’ll walk you through it, step by step.&nbsp;&nbsp;</p><p><br></p><p><strong>It’s important to respond to this Buyer connection request quickly</strong>, as these are typically sent as a part of a sourcing event where your company is being considered.&nbsp;To get going, just click on the Sign Up button.&nbsp;&nbsp;</p>',
};
export const TEMPLATE_KEYS = {
  SenderName: '[[Sender-User-Name]]',
  CompanyName: '[[Sender-Company]]',
};
